<template>
  <div class="mb-32">
      <section class="grid grid-cols-3 pt-8">
        <img src="../assets/images/Firas.png" alt="">
        <img src="../assets/images/Dunya.png" alt="">
        <img src="../assets/images/Nasro.png" alt="">
      </section>

      <section class="mt-16 container mx-auto px-6 xl:px-12">
        <h2 class="text-5xl sm:text-6xl lg:text-8xl xl:text-9xl fontBold ls ">
                     {{ $t("Job.title") }}

         </h2>
        <p class="text-3xl w-4/5	">
              {{ $t("Job.text") }}

         </p>
      </section>

      <section class="mt-32 container mx-auto px-6 xl:px-12">
          <p class="text-4xl">
                               {{ $t("Job.Form") }}
          </p>

          <div class="mt-12">
            <label for="name" class="text-xl text-greey font-light">
                                             {{ $t("Job.Name") }} *
            </label>
            <input v-model="Name" type="text" name="name" id="name" class="block rounded-md py-1 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadow">
          </div>

          <div class="mt-8">
            <label for="email" class="text-xl text-greey font-light">
                  {{ $t("Job.Email") }}  *
            </label>
            <input v-model="Email" type="email" name="email" id="email" class="block rounded-md py-1 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadow">
          </div>

          <div class="mt-8">
            <label for="phone_number" class="text-xl text-greey font-light">
               {{ $t("Job.PhoneNumber") }}  *
            </label>
            <input v-model="PhoneNumber" type="tel" name="phone_number" id="phone_number" class="block rounded-md py-1 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadow">
          </div>

          <div class="mt-8">
            <label for="work" class="text-xl text-greey font-light">
                     {{ $t("Job.TypeOfEmployment") }}  *
            </label>
            <div class="ml-6 mt-4 space-y-4">
                <div class="flex items-center">
                  <input id="push-full-time" name="push-notifications" v-model="TypeOfEmployment" value="Full-time" type="radio" class="h-4 w-4 border-black">
                  <label for="push-full-time" class="ml-3 block text-xl ">
             {{ $t("Job.Type1") }}
                  </label>
                </div>
                
                <div class="flex items-center">
                  <input id="push-part-time" name="push-notifications" v-model="TypeOfEmployment" value="Part-time" type="radio" class="h-4 w-4 border-black">
                  <label for="push-part-time" class="ml-3 block text-xl ">
             {{ $t("Job.Type2") }}
                  </label>
                </div>

                <div class="flex items-center">
                  <input id="push-freelancer" name="push-notifications" v-model="TypeOfEmployment" value="Freelancer" type="radio" class="h-4 w-4 border-black">
                  <label for="push-freelancer" class="ml-3 block text-xl ">
             {{ $t("Job.Type3") }}
                  </label>
                </div>

                <div class="flex items-center">
                  <input id="push-cooperator" name="push-notifications" v-model="TypeOfEmployment" value="Cooperator" type="radio" class="h-4 w-4 border-black">
                  <label for="push-cooperator" class="ml-3 block text-xl ">
             {{ $t("Job.Type4") }}
                  </label>
                </div>
            </div>
          </div>
          <div class="mt-8">
            <label for="interested" class="text-xl text-greey font-light">
                             {{ $t("Job.Interested") }}  *
            </label>
            <select v-model="Interested" id="interested" name="interested" autocomplete="interested" class="block rounded-md py-2 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadowd">
              <option value="Graphic Design">Graphic Design</option>
              <option value="Branding">Branding</option>
              <option value="Photography">Photography</option>
            </select>
          </div>

          <div class="mt-8">
            <label for="address" class="text-xl text-greey font-light">
             {{ $t("Job.Address") }}  *
            </label>
            <input v-model="Address" type="text" name="address" id="address" class="block rounded-md py-1 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadow">
          </div>

          <div class="mt-8">
            <label for="interested" class="text-xl text-greey font-light">
               {{ $t("Job.File") }}  

            </label>


            <div class="flex text-sm text-gray-600">
              <label for="file-upload" class="relative cursor-pointer bg-black rounded-md font-medium text-white focus:outline-none mt-2 hover:shadowd  px-4 w-64 flex justify-center  items-center text-2xl">
                <span class="mt-2 mb-2">Upload</span>
                <input id="file-upload" name="file-upload" type="file" class="sr-only">
              </label>
            </div>
            
          </div>
 <div class="mt-8">
            <label for="interested" class="text-xl text-greey font-light">
               {{ $t("Job.Cv") }}  

            </label>


            <div class="flex text-sm text-gray-600">
              <label for="file-upload" class="relative cursor-pointer bg-black rounded-md font-medium text-white focus:outline-none mt-2 hover:shadowd  px-4 w-64 flex justify-center items-center text-2xl">
                <span class="mt-2 mb-2">Upload</span>
                <input id="file-upload" name="file-upload" type="file" class="sr-only">
              </label>
            </div>
            
          </div>
          <div class="mt-8">
            <label for="about_you" class="text-xl text-greey font-light">
               {{ $t("Job.Tellusmoreabout") }}  
            </label>
            <textarea v-model="TellUsMoreAboutYou" name="about_you" id="about_you" cols="50" rows="4" class="block rounded-md py-2 px-2 w-3/4 border border-gray mt-2 focus:outline-none hover:shadowd"></textarea>
          </div>

          <div class="mt-8">
            <label for="hear" class="text-xl text-greey font-light">
               {{ $t("Job.Howdidyouhearaboutus") }}  
            </label>

            <select v-model="HowDidYouHearAboutUs" id="hear" name="hear" autocomplete="hear" class="block rounded-md py-2 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadowd">
              <option value="Social Media">{{ $t("Job.How-did-you-get-to-know.1") }}</option>
              <option value="Search engines">{{ $t("Job.How-did-you-get-to-know.2") }}</option>
              <option value="Word of mouth">{{ $t("Job.How-did-you-get-to-know.3") }} </option>
              <option value="Other">{{ $t("Job.How-did-you-get-to-know.4") }}</option>

            </select>
          </div>
          <div class="mt-8">
            <label for="notes" class="text-xl text-greey font-light">
               {{ $t("Job.Additionalnotes") }}  
            </label>
            <input v-model="AdditionalNotes" type="text" name="notes" id="notes" class="block rounded-md py-1 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadow">
          </div>

          <div class="mt-8">
            <button  @click="submit" class="bg-black rounded-md font-medium text-white focus:outline-none mt-2 hover:shadowd  px-4 pt-2 pb-2  flex justify-center items-center text-2xl">
                {{ $t("Job.button") }}  
            </button>
          </div>
       </section>

      <section v-if="addSuccessed ==true" class="mt-20 max-w-6xl mx-auto px-6 xl:px-12 text-center">
        <h1 class="text-3xl sm:text-5xl lg:text-7xl sm:leading-12  font-bold">
           {{ $t("Job.titlemsg") }}  
        </h1>
<br>
        <p class="text-2xl sm:text-3xl">
            {{ $t("Job.Massge") }}  
        </p>
      </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
    Name: "",
    Email: "",
    PhoneNumber: "",
    TypeOfEmployment:"",
    Interested: "",
    Address: "",
    File:"",
    CV:'',
    TellUsMoreAboutYou: '',
    HowDidYouHearAboutUs: "",
    AdditionalNotes: '',
    addSuccessed:false,
    addErorr:'',
    res:'',
    
    };
  },
   methods: {
    submit() {
      var form = {
        Name: this.Name,
        Email: this.Email,
        PhoneNumber: this.PhoneNumber,
        TypeOfEmployment: this.TypeOfEmployment,
        Interested: this.Interested,
        Address: this.Address,
        File: this.File,
        CV: this.CV,
        TellUsMoreAboutYou: this.TellUsMoreAboutYou,
        HowDidYouHearAboutUs: this.HowDidYouHearAboutUs,
        AdditionalNotes: this.AdditionalNotes,
       };
        this.$http.jobService
          .AddJobs(form)
          .then((res) => {
            this.res = res;
            this.addSuccessed = true;
          })
          .catch((err) => {
            this.addErorr = err.response.data;
          });
    },
  },
};
</script>